<template>
  <div>
    <div class="main-title">权限变更</div>
    <div class="detail-info">
      <el-form ref="ruleForm" :model="form" :rules="rules" label-width="120px">
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="报单ID" prop="salesId">
          <el-input v-model="form.salesId"></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="actionType">
          <el-select v-model="form.actionType" placeholder="请选择类型">
            <el-option :value="1" label="课程转移"></el-option>
            <el-option :value="2" label="开通课程"></el-option>
            <el-option :value="3" label="关闭课程"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="form.remarks" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="附件">
          <paste-image
            upload-path="question"
            @onRemove="onRemove"
            @onSuccess="onSuccess"
          ></paste-image>
        </el-form-item>
        <div v-if="form.actionType == 1">
          <el-form-item label="转让人">
            <sales-user-course
              v-if="!userInfo.transferor.name"
              @onAdd="addUserCourse"
            >
            </sales-user-course>
            <div v-else>
              <el-descriptions
                :column="4"
                border
                direction="vertical"
                title="用户信息"
              >
                <template slot="extra">
                  <el-button
                    size="small"
                    type="danger"
                    @click="deleteUserCourse('transferor')"
                    >删除
                  </el-button>
                </template>
                <el-descriptions-item label="用户名"
                  >{{ userInfo.transferor.name }}
                </el-descriptions-item>
                <el-descriptions-item label="头像">
                  <el-avatar :src="userInfo.transferor.avatar_url"></el-avatar>
                </el-descriptions-item>
              </el-descriptions>

              <div v-if="courseData.courses.length > 0">
                <b>普通课程</b>
                <el-table :data="courseData.courses" style="width: 100%">
                  <el-table-column label="名字" prop="name"></el-table-column>
                  <el-table-column label="图片">
                    <template slot-scope="scope">
                      <el-image :src="imageUrl + scope.row.pic"></el-image>
                    </template>
                  </el-table-column>
                </el-table>
              </div>

              <div v-if="courseData.checkCourses.length > 0">
                <b>打卡课程</b>
                <el-table :data="courseData.checkCourses" style="width: 100%">
                  <el-table-column label="名字" prop="name"></el-table-column>
                  <el-table-column label="图片">
                    <template slot-scope="scope">
                      <el-image :src="imageUrl + scope.row.pic"></el-image>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="受转让人">
            <select-user
              v-if="!userInfo.transferee.name"
              @onAdds="transfereeFn"
            ></select-user>
            <div v-else>
              <el-descriptions
                :column="4"
                border
                direction="vertical"
                title="用户信息"
              >
                <template slot="extra">
                  <el-button
                    size="small"
                    type="danger"
                    @click="deleteUserCourse('transferee')"
                    >删除
                  </el-button>
                </template>
                <el-descriptions-item label="用户名"
                  >{{ userInfo.transferee.name }}
                </el-descriptions-item>
                <el-descriptions-item label="头像">
                  <el-avatar :src="userInfo.transferee.avatar_url"></el-avatar>
                </el-descriptions-item>
              </el-descriptions>
              <div v-if="courseData.checkCourses.length > 0">
                <b>打卡课程</b>
                <el-table :data="courseData.checkCourses" style="width: 100%">
                  <el-table-column label="名字" prop="name" width="200">
                  </el-table-column>
                  <el-table-column label="图片">
                    <template slot-scope="scope" width="400">
                      <el-image :src="imageUrl + scope.row.pic"></el-image>
                    </template>
                  </el-table-column>
                  <el-table-column label="课程时间">
                    <template slot-scope="scope">
                      <div>
                        <el-form-item label="开始时间">
                          <el-date-picker
                            v-model="scope.row.start_at"
                            placeholder="选择日期时间"
                            value-format="yyyy-MM-dd"
                          >
                          </el-date-picker>
                        </el-form-item>
                        <el-form-item label="是否全部计划">
                          <el-switch
                            v-model="scope.row.is_all"
                            active-color="#13ce66"
                            inactive-color="#ff4949"
                          >
                          </el-switch>
                        </el-form-item>
                        <el-form-item
                          v-if="!scope.row.is_all"
                          label="开始计划天数"
                        >
                          <el-input
                            v-model="scope.row.start_plan_day"
                            placeholder="请输入开始天数"
                          >
                          </el-input>
                        </el-form-item>
                        <el-form-item
                          v-if="!scope.row.is_all"
                          label="结束计划天数"
                        >
                          <el-input
                            v-model="scope.row.end_plan_day"
                            placeholder="请输入结束天数"
                          >
                          </el-input>
                        </el-form-item>
                        <el-form-item label="更新周期">
                          <el-checkbox-group
                            v-if="str"
                            v-model="scope.row.update_day"
                            size="small"
                          >
                            <el-checkbox-button
                              v-for="(city, index) in [
                                '日',
                                '一',
                                '二',
                                '三',
                                '四',
                                '五',
                                '六',
                              ]"
                              :key="city"
                              :label="index"
                              @change="selectDate(scope.row)"
                            >
                              周{{ city }}
                            </el-checkbox-button>
                          </el-checkbox-group>
                        </el-form-item>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </el-form-item>
        </div>

        <div v-if="form.actionType === 2">
          <el-form-item label="开通信息：" label-position="left">
            <sales-course-user @onAdd="addUserCourse"></sales-course-user>
          </el-form-item>
          <el-form-item
            v-for="(userCourse, index) in forms.userCourse"
            :key="index"
            label=""
            label-position="left"
          >
            <el-descriptions :column="2" border size="mini" title="">
              <template slot="extra">
                <el-button
                  size="small"
                  type="danger"
                  @click="deleteUserCourse(index)"
                  >删除
                </el-button>
              </template>
              <el-descriptions-item label="用户ID"
                >{{ userCourse.user_id }}
              </el-descriptions-item>
              <el-descriptions-item label="用户名">
                <div>
                  <el-avatar
                    :src="userCourse.user_name_avatar_url"
                    size="small"
                  ></el-avatar>
                </div>
                <div>{{ userCourse.user_name }}</div>
              </el-descriptions-item>
              <el-descriptions-item label="课程ID"
                >{{ userCourse.course_id }}
              </el-descriptions-item>
              <el-descriptions-item label="课程名">
                <div>
                  <el-image
                    :src="imageUrl + userCourse.course_pic"
                    fit="contain"
                    style="width: 100px"
                  ></el-image>
                </div>
                <div>{{ userCourse.course_name }}</div>
              </el-descriptions-item>
              <el-descriptions-item label="课程类型"
                >{{
                  userCourse.course_type === "checkCourse"
                    ? "阅读日程"
                    : "录播课程"
                }}
              </el-descriptions-item>

              <template v-if="userCourse.course_type === 'checkCourse'">
                <el-descriptions-item :span="2" label="开始更新时间"
                  >{{ userCourse.start_at }}
                </el-descriptions-item>
                <el-descriptions-item :span="2" label="是否全部计划">
                  <el-tag v-if="userCourse.is_all" size="small">是</el-tag>
                  <el-tag v-else size="small" type="danger">否</el-tag>
                </el-descriptions-item>
                <template v-if="!userCourse.is_all">
                  <el-descriptions-item label="开始计划天数"
                    >{{ userCourse.start_plan_day }}
                  </el-descriptions-item>
                  <el-descriptions-item label="结束计划天数"
                    >{{ userCourse.end_plan_day }}
                  </el-descriptions-item>
                </template>
                <el-descriptions-item :span="2" label="更新周期"
                  >{{ userCourse.update_day }}
                </el-descriptions-item>
              </template>
            </el-descriptions>
          </el-form-item>
        </div>

        <div v-if="form.actionType == 3">
          <el-form-item label="人员选择">
            <sales-user-course
              v-if="!userInfo.transferor.name"
              @onAdd="addUserCourse"
            >
            </sales-user-course>
            <div v-else>
              <el-descriptions
                :column="4"
                border
                direction="vertical"
                title="用户信息"
              >
                <template slot="extra">
                  <el-button
                    size="small"
                    type="danger"
                    @click="deleteUserCourse('transferor')"
                    >删除
                  </el-button>
                </template>
                <el-descriptions-item label="用户名"
                  >{{ userInfo.transferor.name }}
                </el-descriptions-item>
                <el-descriptions-item label="头像">
                  <el-avatar :src="userInfo.transferor.avatar_url"></el-avatar>
                </el-descriptions-item>
              </el-descriptions>

              <div v-if="courseData.courses.length > 0">
                <b>普通课程</b>
                <el-table :data="courseData.courses" style="width: 100%">
                  <el-table-column label="名字" prop="name"></el-table-column>
                  <el-table-column label="图片">
                    <template slot-scope="scope">
                      <el-image :src="imageUrl + scope.row.pic"></el-image>
                    </template>
                  </el-table-column>
                </el-table>
              </div>

              <div v-if="courseData.checkCourses.length > 0">
                <b>打卡课程</b>
                <el-table :data="courseData.checkCourses" style="width: 100%">
                  <el-table-column label="名字" prop="name"></el-table-column>
                  <el-table-column label="图片">
                    <template slot-scope="scope">
                      <el-image :src="imageUrl + scope.row.pic"></el-image>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </el-form-item>
        </div>

        <el-form-item>
          <el-button
            :loading="bottonLoading"
            type="primary"
            @click="submitForm('ruleForm')"
            >立即创建
          </el-button>
          <el-button @click="$router.go(-1)">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import PasteImage from "../../../components/pasteImage/index";
import salesUserCourse from "../../../components/sales/salesUserCourse.vue";
import selectUser from "../../../components/sales/selectUser.vue";
import SalesCourseUser from "../../../components/sales/salesCourseUser";

export default {
  name: "add",
  components: {
    salesUserCourse,
    PasteImage,
    selectUser,
    SalesCourseUser,
  },
  data() {
    return {
      forms: {
        userCourse: [],
      },
      imageUrl: config.imageUrl,
      //表单数据
      form: {
        name: "", // 名称
        actionType: null, // 申请类型 => 课程转移
        remarks: "", // 备注
        attachFiles: [], // 附件
        courses: [], // 普通课程
        checkCourses: {}, // 打卡课程
        apply: [],
      },
      //表单验证
      rules: {
        name: [
          { required: true, message: "请输入名称", trigger: "blur" },
          { min: 2, message: "长度在 2 以上", trigger: "blur" },
        ],
        actionType: [
          { required: true, message: "请选择类型", trigger: "change" },
        ],
        salesId: [{ required: true, message: "请输入课程ID", trigger: "blur" }],
      },
      //对话框
      dialogVisible: false,
      //搜索用户
      searchType: 1,
      //用户
      studentName: "",
      //查找到了用户列表
      users: [],
      //判断是否查找到了数据
      notData: "",
      //课程列表
      courseList: [],
      //选择用户的数据
      selectCurrentUser: {},
      //转移的对象
      transfer: {},
      //用户信息
      userInfo: {
        transferor: {},
        transferee: {},
      },
      //转让的课程
      courseData: {
        courses: [],
        checkCourses: [],
      },
      str: true,
      bottonLoading: false,
    };
  },
  methods: {
    //vuex
    ...mapActions("user", ["getUserList"]),
    ...mapActions("sales", ["userCourseOperation"]),
    selectDate(row) {
      this.str = false;
      this.courseData.checkCourses.forEach((v) => {
        if (v.id == row.id) {
          v.update_day = row.update_day;
          this.str = true;
        }
      });
    },
    addUserCourse(info) {
      if (this.form.actionType == 1) {
        this.userInfo.transferor = info.userInfo;
        info.checkCourses.forEach((v) => {
          v.is_all = v.is_all == 1 ? true : false;
          v.update_day = v.update_day ? v.update_day : [1, 2, 4, 5];
        });
        Object.assign(this.courseData, info);
      } else if (this.form.actionType == 2) {
        this.forms.userCourse.push(info);
      } else {
        this.userInfo.transferor = info.userInfo;
        Object.assign(this.courseData, info);
      }
    },
    transfereeFn(info) {
      this.userInfo.transferee = info.userInfo;
    },
    //删除用户所选用户信息
    deleteUserCourse(data) {
      if (this.form.actionType == 1) {
        this.userInfo[data] = {};
      } else if (this.form.actionType == 2) {
        this.forms.userCourse.splice(data, 1);
      } else {
        this.userInfo[data] = {};
      }
    },
    // 图片上传
    onSuccess(path) {
      this.form.attachFiles = path;
    },
    // 图片移除
    onRemove(index, file) {
      this.form.attachFiles.splice(index, 1);
    },
    //搜索关键字选择之后触发
    selectUser(id) {
      this.users.forEach((item) => {
        if (id === item.id) {
          Object.assign(this.selectCurrentUser, item);
        }
      });
      this.form.user_id = id;
    },
    //用户进行搜索输入时触发
    remoteMethod(v) {
      let searchData = {};
      if (this.searchType === 1) {
        searchData.name = v;
        searchData.pageSize = 1000;
      } else {
        searchData.uid = v;
        searchData.pageSize = 1000;
      }
      if (v === "") {
        this.$message.error("请输入昵称");
        return;
      }
      this.notData = false;
      this.getUserList(searchData).then((res) => {
        if (res.data.list.length === 0) {
          this.notData = true;
        }
        this.users = res.data.list;
      });
    },
    //点击对话框以外的内容关闭对话框
    handleClose() {
      this.$confirm("关闭之后表单选择的数据会清空，确认关闭？").then((_) => {
        this.dialogVisible = false;
        this.users = [];
        this.selectCurrentUser = {};
        this.notData = false;
        this.studentName = "";
        this.transfer.region = "";
      });
    },
    //选择替换权限的人
    change() {
      this.dialogVisible = true;
    },
    //选择更换权限
    saveUserCourse() {
      this.dialogVisible = false;
    },
    //处理数据
    dataDispose() {
      if (this.form.actionType == 1) {
        let arr = JSON.parse(JSON.stringify(this.courseData.checkCourses));
        arr.forEach((v) => {
          if (v.start_at) {
            v.check_course_id = v.id;
            v.is_all = v.is_all ? 1 : 0;
            v.start_plan_day = v.is_all ? 0 : v.start_plan_day;
            v.end_plan_day = v.is_all ? 0 : v.end_plan_day;
          } else {
            this.$message.error("签到项目需要输入开始时间");
          }
        });
        let courses = [];
        this.courseData.courses.map((i) => {
          courses.push(i.id);
        });
        this.form.checkCourses = arr;
        this.form.courses = courses;
        this.form.transferee = this.userInfo.transferee.id;
        this.form.transferor = this.userInfo.transferor.id;
      } else if (this.form.actionType == 2) {
        let arr = [];
        let checkCourses = [];
        let courses = [];
        this.forms.userCourse.forEach((v, i) => {
          if (v.course_type == "checkCourse") {
            checkCourses = [];
            checkCourses.push({
              check_course_id: v.course_id,
              update_day: v.update_day,
              start_at: v.start_at,
              is_all: v.is_all,
              start_plan_day: v.start_plan_day,
              end_plan_day: v.end_plan_day,
            });
            courses = [];
          } else {
            courses = [];
            courses.push(v.course_id);
            checkCourses = [];
          }
          arr.push({
            userId: v.user_id,
            checkCourses,
            courses,
          });
        });
        this.form.apply = arr;
      } else {
        let courses = [];
        let checkCourses = [];
        this.courseData.courses.map((i) => {
          courses.push(i.id);
        });
        this.courseData.checkCourses.map((i) => {
          checkCourses.push(i.id);
        });
        this.form.userId = this.userInfo.transferor.id;
        this.form.courses = courses;
        this.form.checkCourses = checkCourses;
      }
    },
    //提交表单
    submitForm(formName) {
      this.dataDispose();
      if (this.form.actionType === 1) {
        if (this.userInfo.transferor.id === this.userInfo.transferee.id) {
          this.$message.error("受转让人和转让人不能是同一个人");
          return false;
        }
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitData();
          this.bottonLoading = true;
          this.userCourseOperation(this.form).then((res) => {
            if (res.res_info === "ok") {
              this.bottonLoading = false;
              this.$router.go(-1);
              this.$message.success("创建成功");
            }
          });
        } else {
          this.$message.error("还有必须输入的内容没有输入");
          return false;
        }
      });
    },
    //提交表单验证
    submitData() {
      if (this.form.actionType == 1) {
        let userInfo = this.userInfo;
        if (!userInfo.transferor.name) {
          this.$message.error("转让人没有选择");
          return false;
        }
        if (!userInfo.transferee.name) {
          this.$message.error("受转让人没有选择");
          return false;
        }
        if (userInfo.transferor.id == userInfo.transferee.id) {
          this.$message.error("受转让人和转让人不能是同一个人");
          return false;
        }
      } else if (this.form.actionType == 2) {
        if (!this.forms.userCourse.length > 0) {
          this.$message.error("开通课程的人员没有选择");
          return false;
        }
      } else {
        if (!this.userInfo.transferor.name) {
          this.$message.error("关闭课程的人员没有选择");
          return false;
        }
      }
      return true;
    },
  },
};
</script>

<style scoped>
.el-form-item {
  margin-bottom: 20px;
}

.el-input,
.el-select,
.el-textarea /deep/ {
  width: 40% !important;
}

.el-image {
  width: 120px;
}
</style>